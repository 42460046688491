import React from "react";
import { getLocalStorageKey } from "utils/utils";
import InputField from "components/fields/InputField";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import httpService from "services/http-service";
import { getLocalStorageValue } from "utils/utils";
import Loading from "react-fullscreen-loading";
import { useNavigate, useLocation } from "react-router-dom";
import Sheet from "react-modal-sheet";
import { MdOutlineArrowBack } from "react-icons/md";
import { MdOutlineClose } from "react-icons/md";
import { MaskedInput, createDefaultMaskGenerator } from "react-hook-mask";
import Verify from "./Verify";
import Card from "components/card";
import { FaClone, FaHandHolding, FaHeart, FaInstagram, FaLinkedin, FaUser, FaUserCircle } from "react-icons/fa";
import Chip from '@mui/material/Chip';
import ChipList from "components/chiplist/chiplist";
import { genders } from "utils/common";
import { industries } from "utils/common";
import { friendsGender } from "utils/common";
import { interests } from "utils/common";

const mask = "+1 (999) 999-9999";
const maskGenerator = createDefaultMaskGenerator(mask);

export default function Editprofile() {
  const location = useLocation();
  const [eventName, seteventName] = useState('');
  const userId = getLocalStorageValue("userDetails", "userId");
  const userEmail = getLocalStorageKey("userEmail");
  const userPhoneNumber = getLocalStorageKey("userPhoneNumber");
  const eventId = getLocalStorageKey("eventId");
  const prevName = getLocalStorageValue("userDetails", "userName");
  const [loading, setLoading] = useState(false);
  const [interestType, setInterestType] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [startY, setStartY] = useState(0);
  const [dateError, setDateError] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [firstName, setfirstName] = useState(prevName);
  const [showCancelButton, setShowCancelButton] = useState(false);
  const [instagramUrl, setInstagramUrl] = useState("");
  const [instagramError, setInstagramError] = useState('');
  const userDetails = {
    ...JSON.parse(localStorage.getItem("userDetails")),
  };

  const handleMouseDown = (event) => {
    setStartY(event.clientY);
  };
  const handleMouseUp = (event) => {
    const deltaY = event.clientY - startY;
    if (deltaY > 50) { // Adjust the threshold as needed
      setIsOpen(false);
    }
  };
  const navigate = useNavigate();

  const handleRouteClick = (page) => {
    navigate(page); // Navigate to specific page
  };

  // useEffect(() => {
  //   if (location.pathname.includes("regular")) {
  //     setIsRegular(true);
  //   } else {
  //     setIsRegular(false);
  //   }
  // }, [location.pathname]);

  const handleClearSelection = () => {
    // Clear form data here
    setFormData({ dateOfBirth: '', title: '', /* Clear other fields */ });
    // Close the sheet
    setIsOpen(false);
  };



  const handleUpdateProfile = async () => {
    setLoading(true);
    const endpoint = "/bartenders/updateProfile/" + userId;
    const data = {
      firstName: firstName
    };
    const response = await httpService("PUT", endpoint, data);
    if (response.Error === "HttpException") {
      setLoading(false);
      toast.error(response.Message);
    } else {
      setLoading(false);
      localStorage.setItem(
        "userDetails",
        JSON.stringify({ ...userDetails, userName: firstName })
      );
      toast.success(response.message);
    }
  };



  const handleOpenInterest = (interest) => {
    setInterestType(interest);
    setIsOpen(true);
  };
  const [selectedDateItems, setSelectedDateItems] = useState([]);
  const [selectedNetworkItems, setSelectedNetworkItems] = useState([]);
  const [selectedFriendshipItems, setSelectedFriendshipItems] = useState([]);

  const handleNetworkSelect = (selectedChips) => {

    if (selectedChips && selectedChips.length > 0) {
      setSelectedNetworkItems(selectedChips);
      setFormData({
        ...formData,
        network: true
      })
    } else {
      setFormData({
        ...formData,
        network: false
      })
    }
  };

  const handleFriendshipSelect = (selectedChips) => {

    if (selectedChips && selectedChips.length > 0) {
      setSelectedFriendshipItems(selectedChips);
      setFormData({
        ...formData,
        makeFriends: true
      })
    } else {
      setFormData({
        ...formData,
        makeFriends: false
      })
    }
  };
  const handleDateSelect = (selectedChips) => {
    if (selectedChips && selectedChips.length > 0) {
      setSelectedDateItems(selectedChips);
      setFormData({
        ...formData,
        date: true
      })
    } else {
      setFormData({
        ...formData,
        date: false
      })
    }

  };

  const handlegenderSelect = (genderValue) => {

    setFormData({
      ...formData,
      sex: genderValue[0]
    })
  };

  const [image, setImage] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
        uploadImage(reader.result);
      };
      reader.readAsDataURL(file);

    }
  };

  // Inside your component function
  const [formData, setFormData] = useState({
    firstName: '',
    dateOfBirth: '',
    sex: '',
    accountSetupStatus: 'Setup Completed',
    title: '',
    network: false,
    makeFriends: false,
    date: false,
    dateFor: {
      choices: []
    },
    friendLookingFor: {
      choices: []
    },
    industries: {
      choices: []
    },
    networkGoals: {
      choices: []
    },
    linkedIn: '',
    instagram: ''
  });


  const handleCancelChanges = () => {
    // Reset form data here
    setFormData({
      ...formData,
      firstName: '',
      dateOfBirth: '',
      sex: '',
      title: '',
      network: false,
      makeFriends: false,
      date: false,
      dateFor: {
        choices: []
      },
      friendLookingFor: {
        choices: []
      },
      industries: {
        choices: []
      },
      networkGoals: {
        choices: []
      },
      linkedIn: '',
      instagram: ''
    });
    // Redirect to edit-profile page
    navigate('/auth/events');
  };

  useEffect(() => {
    // Update Instagram URL whenever the Instagram username changes
    if (formData && formData.instagram) {
      setInstagramUrl(`https://www.instagram.com/${formData.instagram}/`);
    }
  }, [formData]);


  useEffect(() => {
    // Check if the user is redirected from the events page
    if (location.state && location.state.fromEventsPage) {
      setShowCancelButton(true);
    }
  }, [location.state]);


  const validateInstagram = (username) => {
    // Regular expression to validate Instagram username format
    const instagramRegex = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,28}$/;
    if (username === '') {
      return true;
    }
    return username.length >= 4 && instagramRegex.test(username);
  };


  const validateForm = () => {
    if ((!validateInstagram(formData.instagram) && !instagramError) || formData.linkedIn.includes('https://linkedin.com/') || formData.linkedIn.includes('https://wwww.linkedin.com/')) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'instagram') {
      if
        (!validateInstagram(value)) {
        setInstagramError('Please enter a valid Instagram username.');
        // Disable the "Save changes" button if validation fails
        setIsFormValid(false);
      }
      else if (!value) {
        setInstagramError('');
        // Enable the "Save changes" button if validation passes
        setIsFormValid(false);
      }
      else {
        setInstagramError('');
        // Enable the "Save changes" button if validation passes
        setIsFormValid(true);
      }
    }
    if (name === 'linkedIn') {
      if
        (value.includes('https://linkedin.com/') || value.includes('https://wwww.linkedin.com/')) {
        setIsFormValid(true);
      }
      else if (!value) {
        setIsFormValid(false);
      }
    }
    if (name === 'dateOfBirth') {
      const today = new Date();
      const birthDate = new Date(value);
      const age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      if (age < 18) {
        setDateError("You must be at least 18 years old");
      } else {
        setDateError(""); // Clear the error message if age is valid
      }
    }
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const validateSubmitForm = () => {
    formData.dateFor.choices = selectedDateItems;
    formData.friendLookingFor.choices = selectedFriendshipItems;
    formData.networkGoals.choices = selectedNetworkItems;

    if (!image) {
      toast.error("Take a selfie or upload a recent photo of you");
      return false;
    }

    // Check if the first name field is empty
    if (!formData.firstName) {
      toast.error("Tell us your first name to continue");
      return false;
    }

    // Check if user didn't select any goal
    if (!formData.date && !formData.network && !formData.makeFriends) {
      toast.error("Pick at least one goal");
      return false;
    }

    // Check if at least one field is filled
    if (!isFormValid) {
      toast.error("Please enter at least one valid social media account");
      return false;
    }

    return true;
  };


  const handleSubmit = async () => {
    if (!validateSubmitForm()) return;

    setLoading(true);

    try {
      const endpoint = `/User/${userId}`;
      const response = await httpService("PUT", endpoint, formData);

      console.log('Response:', response);

      if (response) {
        setLoading(false);
        console.log('Navigation to /auth/events');
        navigate('/auth/events'); // Navigate to specific page
      } else {
        setLoading(false);
        toast.error(response?.message || 'Error updating profile');
      }
    } catch (error) {
      setLoading(false);
      console.error('Error occurred:', error);
      toast.error('An error occurred while updating the profile');
    }
  }

  const uploadImage = async (img) => {
    const postImage = {
      userId: userId,
      userProfileBaseFileDTO: [
        {
          orderId: 1,
          base64: img,
        },
      ],
    };
    setLoading(true);
    const endpoint = `/User/AddUserProfilePhoto`;
    const response = await httpService("POST", endpoint, postImage);
    if (!response) {
      setLoading(false);
      toast.error(response.Message || 'Error uploading profile photos');
    } else {
      setLoading(false);
      toast.success('Profile photo updated successfully');
    }
  };

  useEffect(() => {

    if (!userId) {
      const fetchData = async () => {
        await populateUserData();
        await populateEventData();

      };
      fetchData();

    }
  }, [userId]);
  const populateUserData = async () => {
    setLoading(true);

    try {
      if (!userId) {
        const data = {
          country: '+1',
          phoneNumber: userPhoneNumber,
          email: getLocalStorageKey('userEmail')
        };
        const userResponse = await httpService("POST", "/User", data);

        if (!userResponse) {
          toast.error(userResponse.message || 'Error while registering');
          setLoading(false);
          return;
        } else {
          localStorage.setItem("userDetails", JSON.stringify(userResponse));
          //  const token = getLocalStorageValue('userDetails', 'token');
          if (userResponse?.token) {
            localStorage.setItem("token", userResponse?.token);
          }

        }
      }



    } catch (error) {
      toast.error('An error occurred while fetching data');
      console.error('Fetch data error:', error);
    } finally {
      setLoading(false);
    }
  };
  const populateEventData = async () => {
    setLoading(true);

    try {


      const eventResponse = await httpService("GET", `/Event/${eventId}`);
      if (eventResponse.Error === "HttpException") {
        toast.error(eventResponse.Message);
        setLoading(false);
        return;
      } else {
        updateEventData(eventResponse);
      }

    } catch (error) {
      toast.error('An error occurred while fetching data');
      console.error('Fetch data error:', error);
    } finally {
      setLoading(false);
    }
  };


  const updateEventData = (eventData) => {
    seteventName(eventData?.name);
  };


  return (
    <div className="mb-16 pt-0 flex h-full w-full justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:justify-start">
      {/* <Loading loading={loading} loaderColor="#252424" /> */}
      <div className="mt-[0vh] w-full max-w-full  flex-col items-center justify-center md:pl-4 lg:pl-0 xl:max-w-[420px]">


        <div className="container mx-auto">

          {loading ? (
            <div className="animate-pulse">
              <div className="flex flex-col items-center mb-[37px] mt-[5px]">
                <div className="h-24 w-24 rounded-full bg-gray-300 mb-4"></div>
                <div className="h-6 w-48 bg-gray-300 rounded mb-2"></div>
              </div>


              <div className="h-10 w-full bg-gray-300 rounded mb-6"></div>

              <div className="mb-4">
                <div className="h-6 w-24 bg-gray-300 rounded mb-2"></div>
                <div className="flex">
                  <div className="h-10 w-28 bg-gray-300 rounded m-2"></div>
                  <div className="h-10 w-36 bg-gray-300 rounded m-2"></div>
                  <div className="h-10 w-40 bg-gray-300 rounded m-2"></div>
                </div>
              </div>
              <div className="mb-4">
                <div className="h-6 w-32 bg-gray-300 rounded mb-2"></div>
                <div className="h-10 w-full bg-gray-300 rounded"></div>
              </div>
              <div className="mb-4">
                <div className="h-6 w-32 bg-gray-300 rounded mb-2"></div>
                <div className="h-10 w-full bg-gray-300 rounded"></div>
              </div>
              <div className="mb-4">

                <div className="w-full rounded-xl bg-gray-300 dark:bg-gray-700 h-10 animate-pulse"></div>
              </div>

            </div>
          ) : (<>
            <div className="align-center mb-[37px] mt-[5px] flex justify-center text-xl font-bold text-navy-700 dark:text-white">
              <div className="flex flex-col items-center justify-center">
                <div className="flex items-center justify-center rounded-full bg-gray-200 h-24 w-24 mb-4">
                  {image ? (
                    <img
                      src={image}
                      alt="User"
                      className="rounded-full h-24 w-24 object-cover"
                    />
                  ) : (
                    <label htmlFor="upload" className="cursor-pointer">
                      <FaUserCircle className="text-4xl text-brand-500 dark:text-white" />
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        className="hidden"
                        id="upload"
                      />
                    </label>
                  )}
                </div>
                <label htmlFor="upload" className="cursor-pointer">
                  <h4 className="text-xl font-bold text-brand-500">Add a profile photo</h4>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="hidden"
                    id="upload"
                  />
                </label>
              </div>
            </div>
            <input
              id="firstName"
              type="text"
              name="firstName"
              value={formData?.firstName}
              onChange={handleChange}
              className="mt-1 p-2 w-full rounded-md border border-gray-300 shadow-sm focus:outline-none focus:border-brand-500 focus:ring focus:ring-brand-500 focus:ring-opacity-50 dark:bg-gray-800 dark:border-gray-600 dark:text-white"
              placeholder="Your first name"
            />


            <div className="flex items-center justify-between px-2">
              <div className="flex items-center"></div>
            </div>
            <label htmlFor="instagram" className="block text-sm font-medium text-gray-600 mb-1 mt-3">
              Your goals
            </label>
            <div>
              <div className="flex items-center justify-between">
                <Chip
                  onClick={() => handleOpenInterest('Date')}
                  label="❤️ Dating"
                  variant={formData.date ? "filled" : "outlined"}
                  className="m-2"
                />
                <Chip
                  onClick={() => handleOpenInterest('Network')}
                  label="🤝 Networking"
                  variant={formData.network ? "filled" : "outlined"}
                  className="m-2"
                />
                <Chip
                  onClick={() => handleOpenInterest('Friends')}
                  label="🎉 Friendships"
                  variant={formData.makeFriends ? "filled" : "outlined"}
                  className="m-2"
                />
              </div>
            </div>



            <div className="mb-4 w-full">
              <label htmlFor="instagram" className="block text-sm font-medium text-gray-600 mb-1 mt-4">
                Your socials
              </label>
              <div className="relative">
                <input
                  id="instagram"
                  type="text"
                  name="instagram"
                  value={formData.instagram}
                  onChange={handleChange}
                  className={`mb-1 mt-1 p-2 px-6 w-full rounded-md border border-gray-300 shadow-sm focus:outline-none focus:border-brand-500 focus:ring focus:ring-brand-500 focus:ring-opacity-50 dark:bg-gray-800 dark:border-gray-600 dark:text-white ${instagramError ? "border-red-500" : ""}`}
                  placeholder="Your Instagram handle"
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                  <FaInstagram className="h-5 w-5 text-gray-400" />
                </div>
              </div>
              {formData.instagram && (
                <a href={'https://instagram.com/' + formData.instagram} target="_blank" className="text-sm-link-blue mt-1" onClick={() => window.open(instagramUrl, '_blank')}>
                  Tap to test your Instagram profile link.
                </a>
              )}
              {/* Display error message */}
              {instagramError && <p className="text-red-500 text-sm mt-1">{instagramError}</p>}

            </div>

            <div className="mb-4 w-full">
              <div className="relative">
                <input
                  id="linkedIn"
                  type="text"
                  name="linkedIn"
                  value={formData?.linkedIn}
                  onChange={handleChange}
                  className="mb-1 mt-1 p-2 px-6 w-full rounded-md border border-gray-300 shadow-sm focus:outline-none focus:border-brand-500 focus:ring focus:ring-brand-500 focus:ring-opacity-50 dark:bg-gray-800 dark:border-gray-600 dark:text-white"
                  placeholder="Your LinkedIn (https://linkedin.com/name)"
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                  <FaLinkedin className="h-5 w-5 text-gray-400" />
                </div>
              </div>
              {formData.linkedIn && (
                <a href={formData.linkedIn} target="_blank" className="text-sm-link-blue mt-1" onClick={() => window.open(formData.linkedIn, '_blank')}>
                  Tap to test your LinkedIn profile link.
                </a>
              )}
            </div>
            <button
              onClick={handleSubmit}
              disabled={!(validateForm)}
              className={`linear w-full rounded-xl py-[12px] text-base font-medium text-white duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${formData.firstName && ((validateInstagram(formData.instagram) && !instagramError) || (formData.linkedIn.includes('https://linkedin.com/') || (formData.linkedIn.includes('https://wwww.linkedin.com/')))) ? 'bg-brand-500' : 'bg-gray-400'}`}
            >
              Save changes
            </button>
            <h4 className="mt-5 items-center justify-center font-bold text-navy-700 dark:text-white">
              &nbsp; {eventName}
            </h4>

            {showCancelButton && (
              <button onClick={handleCancelChanges} className="linear mt-3 w-full rounded-xl bg-gray-300 py-[12px] text-base font-medium text-gray-700 duration-200 hover:bg-gray-400 active:bg-gray-500 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 dark:active:bg-gray-800">
                Cancel Changes
              </button>)}
          </>)}

        </div>


        <Sheet isOpen={isOpen} onClose={() => setIsOpen(false)}>
          <Sheet.Container className="custom-sheet-container">
            <Sheet.Header onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
              <div className="drag-handle mt-10" onMouseDown={handleMouseDown} />
              <div className="flex justify-between items-center w-full"> {/* Wrap elements in a flex container */}
                <h2 className="ml-5 mt-5"><b>{'Tell us about you'}</b></h2>
                <button onClick={() => setIsOpen(false)} className="text-gray-700 dark:text-white mr-3 mb-5">
                  <MdOutlineClose className="h-6 w-6" />
                </button>
              </div>
            </Sheet.Header>

            <Sheet.Content className="overflow-y-scroll pb-8 h-[70vh]"> {/* Apply overflow-y-scroll class */}
              <div className="h-full overflow-y-auto hide-scrollbar">
                <div className="sheet-inner-content">
                  {interestType === 'Date' && <div className="p-4">

                    <div className="mb-4">
                      <label htmlFor="interest" className="block text-sm font-medium text-gray-700 mb-1">
                        You're Interested in dating a...
                      </label>
                      <ChipList
                        items={genders} // Replace with the list of interests
                        allowMultipleSelection={true}
                        onSelect={handleDateSelect}
                      />
                    </div>

                    <div className="mb-28">
                      <label htmlFor="interests" className="block text-sm font-medium text-gray-700 mb-1">
                        Your interests
                      </label>
                      <ChipList
                        items={interests}
                        allowMultipleSelection={true}
                        onSelect={handleDateSelect}
                      />
                    </div>

                    {/* Other sections go here */}
                  </div>}
                  {interestType === 'Network' && <div className="p-4">
                    <div className="mb-4">
                      <label htmlFor="gender" className="block text-sm font-medium text-gray-700 mb-1">
                        Your career
                      </label>
                      <input
                        autoFocus
                        className="dark:text-black mt-4 flex h-12 w-full items-center justify-center rounded-xl border border-gray-200 p-3 text-sm outline-none dark:!border-white/10"
                        placeholder="Your work title"
                        inputMode="text"
                        id="title"
                        name="title"
                        type="text"
                        value={formData.title}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="gender" className="block text-sm font-medium text-gray-700 mb-1">
                        Your industries of interest
                      </label>
                      <ChipList
                        items={industries}
                        allowMultipleSelection={true}
                        onSelect={handleNetworkSelect}
                      />
                    </div>

                  </div>}

                  {interestType === 'Friends' && <div className="p-4">

                    <div className="mb-4">
                      <label htmlFor="interests" className="block text-sm font-medium text-gray-700 mb-1">
                        Your interests
                      </label>
                      <ChipList
                        items={interests}
                        allowMultipleSelection={true}
                        onSelect={handleFriendshipSelect}
                      />
                    </div>
                  </div>}
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <div className="sticky-bottom -mb-4 w-full blurry-background">
                    <div className="p-2">
                      <button onClick={() => setIsOpen(false)} className="linear w-full  rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                        Save changes
                      </button>
                    </div>
                    <div className="mb-4 p-2">
                      <button onClick={handleClearSelection} className="linear w-full rounded-xl bg-gray-300 py-[12px] text-base font-medium text-black duration-200 hover:bg-gray-400 active:bg-gray-500 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600 dark:active:bg-gray-800">
                        Clear My Selection
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
        </Sheet>
      </div>
    </div>
  );
}

